<template>
  <view-item title="呼叫中心">
    <en-card class="h-full" body-class="h-full">
      <flex-box>
        <template #default="{ height }">
          <form-query :model="form.data" :method="table.get">
            <en-form-item label="日期范围" >
            <en-date-picker v-model:start="form.data.startDatetime" v-model:end="form.data.endDatetime" class="w-full"></en-date-picker>
          </en-form-item>
            <en-form-item label="员工工号">
              <en-input v-model="form.data.staffNo" ></en-input>
            </en-form-item>
          </form-query>
   
        <table-dynamic
          :height="600"
          code="CLRDFLD"
          :data="table.data"
          :loading="table.loading"
          :paging="table.paging"
          :method="table.get"
          pagination
          :config="table.config"
        >
        </table-dynamic>
        </template>
      </flex-box>
    </en-card>
  </view-item>
</template>

<script lang="ts">
export default factory({
  config: {
    children: {
      form: {
        data: {
          startDatetime: '',
          endDatetime: '',
          staffNo: ''
        }
      },
      table: {
        ajax: {
          get: {
            action: 'GET /enocloud/callrecord',
            data: 'array',
            loading: true,
            pagination: true,
            params(params){
              params.payload= this.form.data
            }
          }
        }
      }
    }
  },

  mounted() {
    this.table.get()
  }
})
</script>
